import Vue from 'vue'

Vue.directive('validate-role', {
  inserted: function (el, binding) {
    const roleUser = JSON.parse(atob(localStorage.getItem('uid'))).rol
    const arDatos = binding.value

    if (Array.isArray(arDatos) && arDatos.includes(roleUser)) {
      el.style.display = 'none'
      return true
    }

    return false
  },
})
